/*============= Animation Play Video Button Pulse New ========== */
.mfp-iframe-holder .mfp-close {
  top: -52px;
  color:hsl(var(--white));
  font-size: 21px;
  font-weight: 800;
  width: 42px;
  height: 35px;
  background: hsl(var(--base));
  text-align: center;
  line-height: 32px;
  padding: 0;
  opacity: 1;
  &:hover {
    opacity: .9;
  }
}
.play-video {
    height: 90px;
    width: 90px;
    background: hsl(var(--white));
    line-height: 94px;
    display: inline-block;
    border-radius: 10px;
    position: relative;
    z-index: 3;
    text-align: center;
}
.video-main {
	position: relative;
	display: inline-block;
  }
  @keyframes waves {
	0% {
	  -webkit-transform: scale(0.2, 0.2);
	  transform: scale(0.2, 0.2);
	  opacity: 0;
	  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}
	50% {
	  opacity: 0.9;
	  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	}
	100% {
	  -webkit-transform: scale(0.9, 0.9);
	  transform: scale(0.9, 0.9);
	  opacity: 0;
	  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}
  }
  
  .fa-play:before {
	content: "\f04b";
  color: hsl(var(--base));
  }
  
  .waves {
    position: absolute;
    width: 150px;
    height: 150px;
    background: hsl(var(--base));
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    border-radius: 10%;
    right: -29px;
    bottom: -30px;
    z-index: 1;
    -webkit-animation: waves 3s ease-in-out infinite;
    animation: waves 3s ease-in-out infinite;
  }
		  
  .wave-1 {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
  }
  
  .wave-2 {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
  }
  
  .wave-3 {
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
  }
