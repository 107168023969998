/*====================== Ring Section====================*/
.ring-content {
    padding-left: 50px;
    @include md-screen {
        padding-left: 0px;
    }
}
.ring{
    &__thumb {
        height: 370px;
        img {
            @include fit-image;
        }
    }
}