
/*=================== Contact top Start =========*/

.contact-info {
    &__addres-wrap {
        .single_wrapper {
            border: 1px solid hsl(var(--white)/0.1);
            padding: 32px;
            background-color: hsl(var(--dark) / .3); 
            h4 {
                margin-bottom: 24px;
            }
            h3 {
                margin-bottom: 30px;
                text-transform: capitalize;
                color: hsl(var(--white));
              }
            & .single-info {
                display: flex;
                align-items: center;
                
                & .cont-icon {
                    margin-right: 25px;
                    & i {
                        font-size: 40px;
                        color: hsl(var(--base));
                      }
                }
                & .cont-text  {
                      & h6 {
                        font-size: 16px;
                        font-weight: 400;
                         a {
                            color: hsl(var(--white) / 0.8);
                         }
                    }
                }
            }
        }
    }
}
// Contact top End


// Contact Bottom Start
.contact-map {
    width: 100%;
    min-height: 300px;
    height: 100%;
    iframe {
        width: 100%;
        height: 400px;
    }
}

.contactus-form {
    border: 1px solid hsl(var(--white) / 0.1);
    background-color: hsl(var(--balck) / .1);
    padding: 40px 30px;
    @include sm-screen {
        padding: 30px 20px;
    }
    @include msm-screen {
        padding: 20px 15px;
    }
}
// Contact Bottom End

