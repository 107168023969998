
/*====================== Breadcumb Css Start ====================*/
.breadcumb {
    position: relative;
    z-index: 1;
    // padding: 100px 0 0px;
    z-index: 1;
    &__wrapper {
        text-align: left;
        position: relative;
    }
    &__title {
        margin-bottom: 10px;
        color: hsl(var(--white)); 
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: 40px
    }
    &__item {
        color: hsl(var(--white)); 
        padding: 0 5px;
        font-weight: 400;
        font-size: 20px;
            @include msm-screen {
                font-size: 18px;
            }
        &-text {
            color: hsl(var(--white) / .8); ;
        }
    }
    &__link {
        color: hsl(var(--white) / .8);  
        font-weight: 500;
        &:hover {
            color: hsl(var(--base)); ;
        }
    }
}
// ====================== Breadcumb Css End ====================
