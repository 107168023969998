
/*============= Footer Start Here =======================*/
.footer-area {
    background-color: hsl(var(--black) /.6) !important;
    margin-top: auto;
    &.bg-img {
        background-size: contain;
        background-position: top center;
    }
}

.footer-item {
    &__desc {
        color: hsl(var(--white));
    }
    &__logo {
        margin-bottom: 30px;
        a {
            img {
                width: 100%;
                height: 100%;
                max-width: 160px;
                max-height: 64px;
                margin-bottom: 10px;
            }
        }
    }
    &__title {
        color: hsl(var(--white));
        padding-bottom: 10px;
        margin-bottom: 25px;
        position: relative;
        &::after {
            position: absolute;
            content: "";
            width: 30px;
            height: 3px;
            left: 0;
            bottom: -2px;
            background-color: hsl(var(--base));
        }
    }
    .social-list {
        margin: 10px;
        @include md-screen {
            margin-top: 10px;
        }
        @include msm-screen {
            margin-top: 10px;
        }
    }
}
// Footer List Item
.footer-menu {
    display: flex;
    margin: 5px 0;
    justify-content: center;
    &__item {
        display: block;
        padding: 5px 0;
    }
    &__link {
        padding: 0 0 0 12px;
        position: relative;
        color: hsl(var(--white));
        i {
            color: hsl(var(--base));
            font-size: 15px;
        }
        &:hover {
            color: hsl(var(--base));
        }
    }
}

// Footer Contact
.footer-contact-menu {
    margin: -6px 0;
    &__item {
        display: flex;
        padding: 6px 0;
        justify-content: center;
        p {
            color: hsl(var(--white));
        }
        &-icon {
            width: 15px;
            color: hsl(var(--base));
            font-size: 20px;
        }
        &-content {
            padding-left: 15px;
        }
    }
}
// ============= Footer End Here =======================


// ============= Bottom Footer End Here =======================
.bottom-footer {
    border-top: 1px solid hsl(var(--white) / .1);
    &__left {
        @include xsm-screen {
            font-size: 15px;
        }
        a {
            color: hsl(var(--base));
            font-weight: 600;
            padding: 0 10px;
           
            &:hover {
                color: hsl(var(--white));
            }
        }
    }
}
// =============Bottom Footer End Here =======================

