/*====================== About Section====================*/
.about-section {
    overflow: hidden;
}
.about-right-content {
    padding-left: 30px;
}
.about-thumb {
    padding-right: 30px;
    position: relative;
    &__offer {
        width: 150px;
        height: 150px;
        background-color: hsl(var(--base));
        text-align: center;
        line-height: 1.3;
        position: absolute;
        padding: 20px;
        top: 50%;
        right: -8px;
        transform: translateY(-50%);
        @include md-screen {
            right: 55px;
        }
        span {
            font-size: 17px;
            font-weight: 500;
        }
        h3 {
            font-size: 45px;
            line-height: 1.2;
            margin-bottom: 0;
            i.percent {
                font-size: 30px;
            }
        }
    }
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
        100% {
            transform: rotate(360deg);
    }
}
@keyframes spin2 {
    0% {
        transform: rotate(0deg);
    }
        100% {
            transform: rotate(-360deg);
    }
}
