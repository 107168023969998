/*============= Breaking News =============*/
.subscribe-wrap {
    padding: 70px;
    border: 1px solid hsl(var(--base)/.2);
    border-radius: 0px;
    @include sm-screen {
        padding: 40px;
    }
    @include xxsm-screen {
        padding: 20px;
    }
    p {
        margin-bottom: 20px;
    }
    &__input {
        position: relative;
        input {
            height: 60px;
            border-radius: 0px;
            background-color:hsl(var(--base)/0.05);
            padding-right: 70px;
        }
        button {
            position: absolute;
            height: 100%;
            width: 62px;
            background-color: hsl(var(--base));
            border-radius: 0px 0px 0px 0;
            right: 0;
        }
    }
}
