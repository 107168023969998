/*====================== Modal Style ====================*/
.custom--modal .modal-dialog {
    justify-content: center;
    height: 100%;
    align-items: center;
    display: flex;
}
.custom--modal {
    .modal-header {
        border-bottom: 1px solid hsl(var(--white) / .1);
        margin-bottom: 25px;
        padding: 25px 25px 15px 25px;
        .btn-close {
            background-color: hsl(var(--white)) !important;
            line-height: 1;
            border-radius: 4px;
            transition: .2s linear;
            &:hover {
                background-color: hsl(var(--danger-l-100));
            }
            :focus {
                box-shadow: none;
              }
        } 
    }
    .modal-content {
        text-align: center;
        background-color: hsl(var(--dark));
        border-radius: 10px !important;
        min-width: 900px;
        @include md-screen {
            min-width: 700px;
        }
        @include sm-screen {
            min-width: 500px;
        }
        @include msm-screen {
            min-width: 400px;
        }
        @include xsm-screen {
            min-width: 300px;
        }
    }
   
    .modal-body {
    padding: 0 25px 25px;
    }
    .modal-icon i {
        font-size: 32px;
        color: hsl(var(--base)); 
        border: 3px solid hsl(var(--base)); 
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .modal-footer {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        padding: 10px 25px 15px;
        border-top: 1px solid hsl(var(--white) / .1);
        justify-content: flex-end;
    }
}
  