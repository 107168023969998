/*====================== Offer ====================*/
.offer-area {
    position: relative;
    z-index: 1;
    &:before {
        content: "";
        position: absolute;
        background-color: hsl(var(--base));
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
        opacity: 0.75;
    }
}


.offer {
    &__title {
        color: hsl(var(--white));
        margin-bottom: 8px;
    }
    &__desc {
        color: hsl(var(--white));
        margin-bottom: 20px;
    }
}
