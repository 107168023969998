@mixin fit-image {
  width: 100%;
  height: 100%;
  object-fit: cover;  
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
@mixin flex-align {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@mixin flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

//Mobile Landscape
@mixin landscape {
  @media all and (orientation:landscape) {
    @content;
  }
}

//Mobile Potrait
@mixin potrait {
  @media all and (orientation:potrait) {
    @content;
  }
}
  
//Extra Extra Small Screen
@mixin xxsm-screen {
  @media screen and (max-width: 374px) {
    @content;
  }
}

// Xtra Small
@mixin xsm-screen {
  @media screen and (max-width: 424px) {
    @content;
  }
}
// Medium Small Screen (max-width: 575)
@mixin msm-screen {
  @media screen and (max-width: 575px) {
    @content;
  }
}

//Small Screen (max-width: 767)
@mixin sm-screen {
  @media screen and (max-width: 767px) {
    @content;
  }
}

//Large Screen (max-width: 991)
@mixin md-screen {
  @media screen and (max-width: 991px) {
    @content;
  }
}

//Xtra Large Screen (max-width: 1199)
@mixin lg-screen {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

//Xtra Large Screen i (max-width: 1399)
@mixin xl-screen {
  @media screen and (max-width: 1399px) {
    @content;
  }
}

//Xtra Large Screen ii (max-width: 1499)
@mixin xxl-screen {
  @media screen and (max-width: 1499px) {
    @content;
  }
}
//Xtra Large Screen ii (max-width: 1599)
@mixin xxxl-screen {
  @media screen and (max-width: 1599px) {
    @content;
  }
}

