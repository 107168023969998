.error-wrap {
    img {
        background: hsl(var(--danger));
    }
    &__title {
        margin-bottom: 0;
    }
    &__desc {
        margin-bottom: 30px;
        font-size: 18px;
    }
    .error-big {
        font-size: 130px;
        margin-bottom: 0;
    }
}  