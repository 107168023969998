/*============= Banner area start  =============*/
.banner-section {
    position: relative;
    z-index: 1;
    padding: 140px 0 140px;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    @include msm-screen {
        padding: 110px 0 60px
    }
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: hsl(var(--black));
        z-index: -1;
        opacity: 0.9;
    }
}
.banner-left{
    &__content {
        span {
            font-size: 22px;
            text-transform: capitalize;
            font-weight: 600;
            border: 2px solid hsl(var(--base));
            padding: 5px 16px;
            display: inline-block;
            margin-bottom: 25px;
            color: hsl(var(--base));
        }
        h2 {
            font-size: 55px;
            text-transform: uppercase;
            margin-bottom: 5px;
            font-weight: 700;
            color:  hsl(var(--base));
            @include lg-screen {
                font-size: 40px;
            }
        }
        p {
            margin-bottom: 35px;
            color:  hsl(var(--white));
        }
    }
}

.banner-right-wrap {
    text-align: right;
    @include xxxl-screen {
        text-align: center;
    }
    @include sm-screen {
        text-align: left;
    }
    @include msm-screen {
        text-align: center;
    }
    img {
        width: 580px;
        object-fit: cover;
        @include xxxl-screen {
            width: 450px;
        }
        @include xl-screen {
            width: 400px;
        }
        @include msm-screen {
            width: 300px;
        }
        @include xxsm-screen {
            width: 250px;
        }
    }
}