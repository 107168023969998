/*================== Tab Content =============*/
.custom--tab {
    justify-content: center;
    border-radius: 6px;
    margin-bottom: 40px;
    .nav-item {
        border-bottom: 0;
        padding: 5px;
        .nav-link {
            color: hsl(var(--white)); 
            padding: 8px 25px;
            background-color: transparent !important;
             border-radius: 5px;
            transition: .4s;
            border: 1px solid hsl(var(--black) / .08) !important;
            @include lg-screen {
                padding: 12px 15px;
            }
            &.active {
                color: hsl(var(--white)); 
                background-color: hsl(var(--base-d-200)) !important;
                border: 1px solid transparent !important;
                &:hover {
                    color: hsl(var(--white)); 
                }
            }
            &:hover {
                color: hsl(var(--base));
            }
        }
    }
}
