.section-bg {
    background-color: hsl(var(--dark) /.5);
}
.section-bg-two {
    background-color: hsl(var(--dark) / .9);
}
.full-display {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.header-transparent {
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
}