/*====================== Reservation Style ====================*/
.reservations {
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: hsl(var(--black));
        opacity: 0.8;
    }
    .contactus-form {
        position: relative;
        z-index: 1;
        background: hsl(var(--black) / .85);
    }
}