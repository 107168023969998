
/*====================== Blog Style ====================*/
.blog-item {
    box-shadow: var(--box-shadow);
    border-radius: 0px;
    overflow: hidden;
    height: 100%;
    &:hover {
        .blog-item {
            &__thumb {
                img {
                    transform: scale(1.2);
                }
            }
            &__title {
                img {
                    color: hsl(var(--base));
                }
            }
        }
    }
    &__thumb {
        overflow: hidden;
        border-radius: 0px;
        max-height: 300px;
        img {
            transition: .3s linear;
            border-radius: 0;
            width: 100%;
            height: 100%;
            @include fit_image; 
        }
    }
    &__content {
        background-color: hsl(var(--white)/.07); 
        padding: 35px 25px;
        height: 100%;
        @include sm-screen {
            padding: 30px 20px;
        }
        @include xsm-screen {
            padding: 25px 15px;
        }
    }
    &__title {
        margin-top: 20px;
        @include msm-screen {
            margin-top: 15px;
        }
        &-link {
            overflow:hidden;
            text-overflow:ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    &__desc {
        margin-bottom: 25px;
        overflow:hidden;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        @include sm-screen {
            margin-bottom: 15px;
        }
    }
}