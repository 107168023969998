/* <=====================> Portfolio Area Start Here <===================> */
.single-menu {
    overflow: hidden;
    cursor: pointer;
    &:hover{
        .single-menu__thumb {
            img {
                transform: scale(1.03);
            }
        }
    }
    &__thumb {
        position: relative;
        object-fit: cover;
        img {
            transform: scale(1);
            transition: all linear .4s;
            width: 100%;
            height: 100%;
        }
        .offer-batch {
            position: absolute;
            top: 10px;
            right: 12px;
            span {
                padding: 2px 10px;
                color: hsl(var(--dark));
                display: block;
                position: relative;
                z-index: 9;
                &::before {
                    content: "";
                    position: absolute;
                    left: -3px;
                    top: -4px;
                    height: 131%;
                    width: 105%;
                    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
                    background: hsl(var(--base));
                    z-index: -1;
                }
            }
        }
    }
    &__context {
        position: relative;
        padding: 30px 20px;
        padding-top: 45px;
        border: 1px solid hsl(var(--white) / .1);
        border-top: 0;
        h3 {
            margin-bottom: 5px;
        }
        .price {
            position: absolute;
            background-color: hsl(var(--base)/0.9);
            display: block;
            padding: 2px 25px;
            color: hsl(var(--white));
            font-weight: 500;
            left: 50%;
            transform: translateX(-50%);
            top: -16px;
            border: 1px solid hsl(var(--base));
            font-size: 22px;
        }
    }
}



.our-menu-item  {
    margin-bottom: 25px;
    button {
        font-size: 18px;
        background: hsl(var(--white));
        color: hsl(var(--black));
        padding: 5px 20px;
        margin: 10px;
        margin-top: 0px;
        transition: all linear .4s;
        &:hover {
            background-color: hsl(var(--base));
        }
        &.active {
            background-color: hsl(var(--base));
        }
    } 
}

.modal {
    &__context {
        text-align: left;
        padding: 15px;
        padding-bottom: 0;
        h3 {
            margin-bottom: 10px;
        }
        .price {
            font-size: 25px;
            color: hsl(var(--white) / .4);
            margin-bottom: 5px;
            display: block;
        }
    }
    &__tags {
        span {
            font-size: 18px;
        }
    }
}
.custom--modal .modal-body .single-menu {
    cursor: inherit;
}